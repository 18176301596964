<template>
  <div class="filter">
    <button class="filter__close" @click="closeFilters">
      <svg>
        <use xlink:href="@/assets/images/sprites.svg#icon-close"></use>
      </svg>
    </button>
    <h2 class="filter__title">Параметры поиска</h2>
    <button class="filter__reset for-desktop" @click="cancelFilters">Сбросить</button>

    <form class="filter__form">
      <h3 class="filter__head">Доступность</h3>
      <div class="filter__line" v-for="item in availableFilters.statuses" :key="item.id">
        <input v-model="filters[item.id]" class="filter__input" :id="item.id" type="checkbox" :name="item.id"/>
        <label class="filter__checkbox" :for="item.id">
          <span class="filter__text">{{ item.label }}</span>
          <span class="filter__total">{{ item.value }}</span>
        </label>
      </div>
    </form>

    <form class="filter__form">
      <h3 class="filter__head">Цена в месяц</h3>
      <div class="filter__line" v-for="segment in availableFilters.segments" :key="segment.label">
        <input v-model="filters.segments" class="filter__input" :id="segment.label + segment.id" type="checkbox" name="price" :value="segment.id"/>
        <label class="filter__checkbox" :for="segment.label + segment.id">
          <span class="filter__text">{{ segment.label }}</span>
          <span class="filter__total">{{ segment.count }}</span>
        </label>
      </div>
    </form>

    <form class="filter__form">
      <h3 class="filter__head">Марка</h3>
      <div class="filter__line" v-for="model in allModels" :key="model.name">
        <input v-model="filters.models" class="filter__input" :id="model.name.toLowerCase()" type="checkbox" :name="model.name.toLowerCase()" :value="model.id"/>
        <label class="filter__checkbox" :for="model.name.toLowerCase()">
          <span class="filter__text">{{ model.name }}</span>
          <span class="filter__total">{{ model.count }}</span>
        </label>

        <div v-if="model.classes.length" class="filter__subline">
          <div v-for="rclass in model.classes" class="filter__line" :key="rclass?.brief">
            <input v-model="filters.rent_classes" class="filter__input" :id="rclass?.brief + rclass.id" type="checkbox" :name="rclass?.brief" :value="rclass.id"/>
            <label class="filter__checkbox" :for="rclass?.brief + rclass.id">
              <span class="filter__text">{{ rclass?.brief }}</span>
              <span class="filter__total">{{ rclass.count }}</span>
            </label>
          </div>
        </div>
      </div>
    </form>

    <div class="filter__bottom">
      <div class="filter__button">
        <button class="button" type="button" @click="applyFilters">Показать</button>
        <button class="button button--drop" type="button" @click="dropFilters">Сбросить</button>
      </div>
    </div>
  </div>
</template>

<script setup>
import {useStore} from 'vuex';
import debounce from 'lodash/debounce';
import {computed, nextTick, onMounted, reactive, ref, watch, defineEmits} from 'vue';
import {useRoute, useRouter} from 'vue-router';

const $emit = defineEmits(['showFiltersCount', 'closeFiltersDialog', 'filterCarsRequest']);
const $router = useRouter();

const filters = reactive({
  rent_classes: [],
  models: [],
  segments: [],
  is_not_available: false,
  is_available: false
});
const segmentLabels = {
  0: 'Стандарт',
  1: 'Комфорт',
  2: 'Премиум',
};
const showAllCars = ref(true);
const rentClassModels = ref({});
const modelsRentClasses = ref({});
const allModels = ref([]);
const availableFilters = reactive({
  models: [],
  segments: [],
  statuses: []
});
const dictsIsSet = ref(false);
const queryIsSet = ref(false);
const preSelectedCar = ref(false);

const $route = useRoute();
const $store = useStore();

const activeFilters = computed(() => {
  const segment = filters.segments.length ? 1 : 0;
  const model = filters.models.length ? 1 : 0;
  const status = filters.is_available || filters.is_not_available ? 1 : 0;

  return segment + model + status;
});

const counts = computed(() => $store.getters['cars/counts']);

watch(() => activeFilters.value, (val) => {
  $emit('showFiltersCount', val);
});

const isUpdatingModels = ref(false);
const isUpdatingRentClasses = ref(false);

watch(
  () => filters.rent_classes,
  (newRentClasses, oldRentClasses) => {
    if (isUpdatingModels.value) return;
    isUpdatingRentClasses.value = true;

    const addedRentClasses = newRentClasses.filter((rentClass) => !oldRentClasses.includes(rentClass));

    addedRentClasses.forEach((rentClass) => {
      const relatedModels = Object.keys(modelsRentClasses.value).filter((model) =>
        modelsRentClasses.value[model]?.includes(rentClass)
      );
      relatedModels.forEach((model) => {
        if (!filters.models.includes(Number(model))) {
          filters.models.push(Number(model));
        }
      });
    });

    const removedRentClasses = oldRentClasses.filter((rentClass) => !newRentClasses.includes(rentClass));
    removedRentClasses.forEach((rentClass) => {
      const relatedModels = Object.keys(modelsRentClasses.value).filter((model) =>
        modelsRentClasses.value[model]?.includes(rentClass)
      );
      relatedModels.forEach((model) => {
        const remainingRentClasses = modelsRentClasses.value[model].filter((rc) =>
          newRentClasses.includes(rc)
        );
        if (remainingRentClasses.length === 0) {
          const modelIndex = filters.models.indexOf(Number(model));
          if (modelIndex !== -1) {
            filters.models.splice(modelIndex, 1);
          }
        }
      });
    });

    isUpdatingRentClasses.value = false;
  },
  { deep: true }
);

watch(
  () => filters.models,
  (newModels, oldModels) => {
    if (isUpdatingRentClasses.value) return;
    isUpdatingModels.value = true;

    const addedModels = newModels.filter((model) => !oldModels.includes(model));
    addedModels.forEach((model) => {
      const rentClasses = modelsRentClasses.value[model] || [];
      rentClasses.forEach((rentClass) => {
        if (!filters.rent_classes.includes(rentClass)) {
          filters.rent_classes.push(rentClass);
        }
      });
    });

    const removedModels = oldModels.filter((model) => !newModels.includes(model));
    removedModels.forEach((model) => {
      const rentClasses = modelsRentClasses.value[model] || [];
      rentClasses.forEach((rentClass) => {
        const remainingModels = Object.keys(modelsRentClasses.value).filter((otherModel) =>
          modelsRentClasses.value[otherModel]?.includes(rentClass)
        );
        if (remainingModels.length === 1 && remainingModels[0] === model) {
          const rentClassIndex = filters.rent_classes.indexOf(rentClass);
          if (rentClassIndex !== -1) {
            filters.rent_classes.splice(rentClassIndex, 1);
          }
        }
      });

      rentClasses.forEach((rentClass) => {
        const rentClassIndex = filters.rent_classes.indexOf(rentClass);
        if (rentClassIndex !== -1) {
          filters.rent_classes.splice(rentClassIndex, 1);
        }
      });
    });

    isUpdatingModels.value = false;
  },
  { deep: true }
);

watch(() => filters.segments, (val) => {
  if (!val) return;

  showAllCars.value = true;
});

watch(() => filters, (val) => {
  if(!queryIsSet.value) return;

  debouncedQuery(val);
}, {deep: true});

onMounted( async () => {
  preSelectedCar.value = $route.query && Object.keys($route.query).length !== 0;
  await getAllModels();
  if ($route.query.segment) {
    showAllCars.value = false;
  }

  setAvailableFilters();
  setQueryFilters();
});

const debouncedQuery = debounce(function (newval) {
  filterCarsRequest(newval);
}, 300);

const filterCarsRequest = async (data) => {
  $emit('filterCarsRequest', data, async () => {
    if (preSelectedCar.value) {
      preSelectedCar.value = false;
      await getAllModels(true);
      clearRouter();
      // await setQueryFilters();
      setAvailableFilters();
    } else {
      setAvailableFilters();
    }
  });
};

const getAllModels = async (clear) => {
  if (clear) {
    allModels.value = [];
  }
  if (counts.value.model) {
    counts.value.model.forEach((rentClasses) => {
      const model = {
        id: rentClasses.model_id,
        name: rentClasses.model_name,
        classes: [...rentClasses.rent_classes.map((car) => ({
          id: car.rent_class_id,
          brief: car.rent_class_brief,
          count: car.count,
        }))],
        count: rentClasses.rent_classes.reduce((acc, rentClass) => acc += rentClass.count, 0)
      };
      allModels.value.push(model);
    });
  }
};

const setQueryFilters = () => {
  const query = $route.query;
  const queryClass = Number(query.rclass);
  const querySegment = query.segment;

  switch (querySegment) {
    case 'premium':
      filters.segments.push(2);
      break;
    case 'comfort':
      filters.segments.push(1);
      break;
    case 'standart':
      filters.segments.push(0);
      break;
  }

  if (queryClass) {
    const modelIndex = Object.values(modelsRentClasses.value).findIndex((model) => model.includes(queryClass));
    filters.rent_classes.push(queryClass);
    filters.models.push(Number(Object.keys(modelsRentClasses.value)[modelIndex]));
  }

  nextTick(() => {
    queryIsSet.value = true;
  });
};

const setModels = (models) => {
  if (dictsIsSet.value) {
    allModels.value.forEach((item) => {
      item.classes.forEach((classes) => {
        classes.count = 0;
      });
      item.count = 0;
    });
  }
  counts.value.model !== null && Object.values(counts.value.model).forEach(async (rentClasses, index) => {

    const model = {
      id: rentClasses.model_id,
      name: rentClasses.model_name,
      classes: [...rentClasses.rent_classes.map((car) => ({
        id: car.rent_class_id,
        brief: car.rent_class_brief,
        count: car.count,
      }))],
      count: rentClasses.rent_classes.reduce((acc, rentClass) => acc += rentClass.count, 0)
    };

    const id = allModels.value.findIndex(item => item.id === model.id);

    if(!dictsIsSet.value) {
      rentClassModels.value[index] = Number(model.id);
      modelsRentClasses.value[model.id] = model.classes?.map(car => car.id) || [];
    }

    if((showAllCars.value || model.count) && !models.some(item => item.id === model.id)) {
      models.push(model);
    } else {
      model.classes.forEach(car => {
        const classesId = allModels.value[id].classes.findIndex(allCar => car.id === allCar.id);
        allModels.value[id].classes[classesId].count = car.count;
      });

      allModels.value[id].count = allModels.value[id].classes.reduce((acc, car) => acc += car.count, 0);
    }
  });

  if (!preSelectedCar.value) {
    dictsIsSet.value = true;
  }
};

const setSegments = (segments) => {
  Object.entries(counts.value.segment).forEach(([segmentId, segmentCount], index) => {
    segments[index] = {
      id: Number(segmentId),
      count: segmentCount,
      label: segmentLabels[segmentId],
    };
  });
};

const setStatus = (statuses) => {
  statuses[0] = { value: counts.value.is_available, label: 'В наличии', id: 'is_available'};
  statuses[1] = { value: counts.value.is_not_available, label: 'В поставке', id: 'is_not_available'};
};

const setAvailableFilters = () => {
  setSegments(availableFilters.segments);
  setStatus(availableFilters.statuses);
  setModels(availableFilters.models);
};

const dropFilters = () => {
  cancelFilters();
  closeFilters();
};

const applyFilters = () => {
  closeFilters();
};

const closeFilters = () => {
  $emit('closeFiltersDialog');
};

const clearRouter = () => {
  const prefix = $route.path.includes('ul') ? '/ul' : '';
  $router.replace(`${prefix}/catalog`);
};

const cancelFilters = () => {
  filters.rent_classes = [];
  filters.models = [];
  filters.segments = [];
  filters.is_not_available = false;
  filters.is_available = false;
  clearRouter();
};

</script>
